const autoNgTemplateLoaderTemplate1 = require('./wizard-endpoint-type.html');

import angular from 'angular';

import './wizard-endpoint-type.css';

angular.module('portainer.app').component('wizardEndpointType', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    endpointTitle: '@',
    description: '@',
    icon: '@',
    icon2: '@?',
    active: '<',
  },
});
